.header__container {
    max-width: 70rem;
    margin: 0 auto;
    padding: 0 $m-size;
    // display: flex;
    // justify-content: space-between;
}

.header {
    background: $off-black;
    margin-bottom: $m-size;
    color: $off-white;
    padding: $m-size 0;

}

.header__title {
    font-size: $l-size;
    margin: 0;
}

.header__subtitle {
    font-size: $m-size;
    margin: 0;
    color: #3d3c3d;
    font-weight: 500;
}

.header__signup {
    border:none;
    margin: $s-size;
    color: $off-black;
    border-radius: 2px;
    background: white;
}

@media(min-width:45rem) {
    .header {
        margin-bottom: $xl-size;
    }
}
