.big-button{
    position: relative;
    height: 40px;
    width: 100%;
    margin-bottom: $s-size;
    border: none;
    outline: none;
    color: white;
    background: rgb(0, 0, 0);
    cursor: pointer;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
  }
  .big-button:before{
    position: absolute;
    content: '';
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    border-radius: 5px;
    z-index: -1;
    opacity: 0;
    filter: blur(5px);
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    background-size: 400%;
    transition: opacity .3s ease-in-out;
    animation: animate 20s linear infinite;
    opacity: 1;
    filter: blur(4px);
  }

  .big-button:disabled {
    opacity: .1;
  }


  .button--link {
    background: none;
    border: none;
    padding: 0;
    color: $off-white;
  }

  @keyframes animate {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
  }

  .sub-button {
    height: 45px;
    color: $off-white;
    background-color:rgb(35, 119, 255);
    border: none;
    border-radius: 3px;
    
  }


  @media(min-width:45rem) {
    .big-button {
        margin-bottom: $s-size;
    }
}


.filter-button {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: rgb(0, 0, 0);
  color: $off-white;
  border: none;
  font-size: 15px;
  border-radius: 20px;
  padding: 7px;
  border: 2px solid;
  border-color: #48ff00;
  
}



//   button{
//     position: relative;
//     height: 60px;
//     width: 200px;
//     border: none;
//     outline: none;
//     color: white;
//     background: #111;
//     cursor: pointer;
//     border-radius: 5px;
//     font-size: 18px;
//     font-family: 'Raleway', sans-serif;
//   }
//   button:before{
//     position: absolute;
//     content: '';
//     top: -2px;
//     left: -2px;
//     height: calc(100% + 4px);
//     width: calc(100% + 4px);
//     border-radius: 5px;
//     z-index: -1;
//     opacity: 0;
//     filter: blur(5px);
//     background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
//     background-size: 400%;
//     transition: opacity .3s ease-in-out;
//     animation: animate 20s linear infinite;
//   }
//   button:hover:before{
//     opacity: 1;
//   }
//   button:hover:active{
//     background: none;
//   }
//   button:hover:active:before{
//     filter: blur(2px);
//   }
//   @keyframes animate {
//     0% { background-position: 0 0; }
//     50% { background-position: 400% 0; }
//     100% { background-position: 0 0; }
//   }