html {
    font-size: 62.5%;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    background-color: $off-grey;
    overflow: hidden;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}