.ReactModalPortal>div {
    opacity: 0;
  }
  
  .ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity 200ms ease-in-out;
  }
  
  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
    // background-color: aqua;
  }
  
  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .modal {
    background: black;
    color: white;
    max-width: 30rem;
    outline: none;
    padding: $l-size;
    text-align: center;
  }
  
  .modal__title {
    margin: 0 0 $m-size 0;
    font-size: 1rem;
  }
  
  .modal__body {
    font-size: 2rem;
    font-weight: 300;
    margin: 0 0 $l-size 0;
    word-break: break-all;
  }

  .modal__button {
    font-size: 1rem;
    font-weight: 400;
    border-radius: 3px;
    background-color: rgb(35, 182, 255);
    color: black;
    border: none;
  }

  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }