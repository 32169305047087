//colors

$off-white: white;
$off-black: black;
$off-grey: #1e1e1e;

//sizes
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size:3.2rem;
$xl-size: 4.8rem;